import React from "react";
import styles from "./header.module.css";
import Toggle from "../toggle/Toggle";

function Header() {
    const handleLinkClick = (e: React.SyntheticEvent) => {
        const links: HTMLCollection = document.getElementsByTagName("li");
        Array.from(links).forEach((item) => {
            item.classList.remove(`${styles.list_item_active}`);
        });
        (e.target as Element).classList.add(`${styles.list_item_active}`);
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.logo}>Resaw</div>
                <nav className={styles.nav}>
                    <ul className={styles.list}>
                        <li
                            onClick={handleLinkClick}
                            className={`${styles.list_item} ${styles.active}`}
                        >
                            Home
                        </li>
                        <li onClick={handleLinkClick} className={styles.list_item}>
                            About Me
                        </li>
                        <li onClick={handleLinkClick} className={styles.list_item}>
                            Portfolio
                        </li>
                        <li onClick={handleLinkClick} className={styles.list_item}>
                            Contact
                        </li>
                        <div className={styles.indicator} />
                    </ul>

                </nav>
                <Toggle size={30} />
            </header>
        </div>
    );
}

export default Header;
