import React from "react";
import styles from "./home.module.css";
import CompetenceCard from "../components/share/competence-card/Competence-card";

function Home() {
  return (
    <div className={styles.container}>
      <main className={styles.content}>
        {/*<p className={styles.greeting}>*/}
        {/*    Hello there! You have visited my personal website. Here you can see my*/}
        {/*    portfolio, read something about me and contact me.*/}
        {/*</p>*/}

          <div className={styles.competence}>
              <h2 className={styles.title}>My Competencies</h2>
              <div className={styles.comp_cards}>
                  <CompetenceCard />
                  <CompetenceCard />
                  <CompetenceCard />
                  <CompetenceCard />
                  <CompetenceCard />
                  <CompetenceCard />
              </div>
          </div>


      </main>
    </div>
  );
}

export default Home;
