import React from "react";
import Home from "../../pages/Home";
import Header from "../header/Header";
import Canvas from "../canvas/Canvas";

const App = () => {

    if (window.matchMedia("(prefers-color-scheme)").media !== "not all") {
        console.log();
    }

    return (
        <>
            <Header />
            <Home />
            <Canvas />
        </>

    );
}

export default App;
