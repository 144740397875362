import React, {FC, useEffect, useState} from "react";
import { useTheme } from "../../hooks/use-theme";
import "./toggle.css"

interface IToggleProps {
    size?: number;
}

const Toggle: FC<IToggleProps> = ({ size = 20 }) => {
    const { theme, setTheme } = useTheme();
    const [color, setColor] = useState("white");

    const handleThemeClick = () => {
        if (document.documentElement.dataset.theme === "light") {
            setColor("white");
            setTheme("dark");
        } else {

            setColor("black");
            setTheme("light");
        }
    };

    useEffect(() => {
        if (document.documentElement.dataset.theme === "light") {
            document.body.classList.add("transition")
            setColor("black")
        } else {
            document.body.classList.add("transition")
            setColor("white")
        }

    }, [theme])

    return (
        <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleThemeClick}
        >
            <path
                fill={color}
                d="M19 9.199h-.98c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799H19c.552 0 1-.357 1-.799 0-.441-.449-.801-1-.801zM10 4.5c-3.051 0-5.5 2.449-5.5 5.5s2.449 5.5 5.5 5.5c3.05 0 5.5-2.449 5.5-5.5S13.049 4.5 10 4.5zm0 9.5c-2.211 0-4-1.791-4-4 0-2.211 1.789-4 4-4a4 4 0 0 1 0 8zm-7-4c0-.441-.449-.801-1-.801H1c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799h1c.551 0 1-.358 1-.799zm7-7c.441 0 .799-.447.799-1V1c0-.553-.358-1-.799-1-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1zm0 14c-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1 .441 0 .799-.447.799-1v-1c0-.553-.358-1-.799-1zm7.365-13.234c.391-.391.454-.961.142-1.273s-.883-.248-1.272.143l-.7.699c-.391.391-.454.961-.142 1.273s.883.248 1.273-.143l.699-.699zM3.334 15.533l-.7.701c-.391.391-.454.959-.142 1.271s.883.25 1.272-.141l.7-.699c.391-.391.454-.961.142-1.274s-.883-.247-1.272.142zm.431-12.898c-.39-.391-.961-.455-1.273-.143s-.248.883.141 1.274l.7.699c.391.391.96.455 1.272.143s.249-.883-.141-1.273l-.699-.7zm11.769 14.031l.7.699c.391.391.96.453 1.272.143.312-.312.249-.883-.142-1.273l-.699-.699c-.391-.391-.961-.455-1.274-.143s-.248.882.143 1.273z"
            />
        </svg>
    );
};

export default Toggle;
