import React, { useEffect, useRef } from "react";
import styles from "./canvas.module.css";

const Canvas = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasWidth: number = window.innerWidth;
    const canvasHeight: number = window.innerHeight;
    const a: number = (2 * Math.PI) / 6;
    const r: number = 50;

    const pos: HTMLElement = document.documentElement;

    React.useEffect(() => {
        pos.addEventListener("mousemove", (e) => {
            pos.style.setProperty("--x", e.clientX + "px");
            pos.style.setProperty("--y", e.clientY + "px");
        });

        return pos.removeEventListener("mousemove", () => {
            pos.style.removeProperty("--x");
            pos.style.removeProperty("--y");
        });
    }, []);

    const init = (ctx: CanvasRenderingContext2D | null | undefined) => {
        drawGrid(canvasWidth, canvasHeight, ctx);
    };

    const drawGrid = (
        width: number,
        height: number,
        ctx: CanvasRenderingContext2D | null | undefined
    ) => {
        let isEndRow = false;
        for (let y = r; y + r * Math.sin(a) < height; y += r * Math.sin(a)) {
            let newRowX = 0;
            if (isEndRow) {
                newRowX = r + r * Math.cos(a);
                isEndRow = false;
            } else {
                isEndRow = true;
            }
            for (
                let x = r + newRowX;
                x + r * (1 + Math.cos(a)) < width;
                x += 3 * r
            ) {
                drawHexagon(x, y, ctx);
            }
        }
    };

    const drawHexagon = (
        x: number,
        y: number,
        ctx: CanvasRenderingContext2D | null | undefined
    ) => {
        ctx?.beginPath();
        for (let i = 0; i < 6; i++) {
            ctx?.lineTo(x + r * Math.cos(a * i), y + r * Math.sin(a * i));
        }
        ctx?.closePath();
        if (ctx !== undefined && ctx !== null) {
            ctx.strokeStyle = `rgba(
            ${Math.floor(x / 8)}, 
            ${Math.floor(y / 4)},
            ${Math.floor(Math.random() * 255)}, 1)`;
            ctx?.stroke();
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context: CanvasRenderingContext2D | null | undefined =
            canvas?.getContext("2d");
        init(context);
    }, []);

    return (
        <>
            <canvas
                className={styles.canvas}
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
            />
        </>
    );
};

export default Canvas;
