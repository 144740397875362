import React from "react";
import styles from "./competence-card.module.css";

const CompetenceCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.title}>JavaScript</div>
            <div className={styles.description}>
                JavaScript is a programming language that developers use to make
                interactive webpages.
            </div>
        </div>
    );
};

export default CompetenceCard;
